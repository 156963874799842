








import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

import { NavTabsItemProps } from '../../../../dsl/molecules/NavTabs'

import { FormContextSwitchContracts, TabsSwitchTab } from './FormContextSwitch.contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<TabsSwitch>({
  name: 'TabsSwitch',
  created () {
    this.activeTab = this.contexts && this.contexts.tabs ? this.contexts.tabs.active : ''
  }
})
export class TabsSwitch extends Vue {
  @PropSync('mode', { required: true })
  public _mode!: unknown

  @Prop({ type: Object, required: true, default: null })
  public readonly contexts!: FormContextSwitchContracts | null

  public activeTab: string | null = null

  public get tabsProps (): Array<NavTabsItemProps> | undefined {
    if (!this.contexts || !Object.prototype.hasOwnProperty.call(this.contexts, 'tabs')) {
      return
    }
    return this.contexts.tabs!.tabs.map((tab) => {
      return {
        ...tab,
        label: this.$t(`front.shared.tabsSwitch.${tab.label}`).toString()
      }
    })
  }

  /**
   * Update synced 'mode' regarding it's predicted type
   */
  public onActiveTabUpdate (value: string) {
    this.activeTab = value

    switch (typeof this._mode) {
      case 'boolean':
        switch (this.activeTab) {
          case TabsSwitchTab.ShipOnBilling:
            this._mode = true
            break
          case TabsSwitchTab.ShipOnOther:
            this._mode = false
        }
        break
      case 'string':
        // ...cases when 'string'
        break
    }
  }
}

export default TabsSwitch
