





































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { VueConstructor } from 'vue'
import { TranslateResult } from 'vue-i18n'
import {
  AnyObject,
  Authentication,
  AuthServiceType,
  EventbusType,
  IEventbus
} from '@movecloser/front-core'

import {
  AddressData,
  CartBillingAddress,
  CartShippingAddress,
  ShippingMethod
} from '../../../../contexts'
import { Inject, logger } from '../../../../support'
import { StructureConfigurable } from '../../../../support/mixins'

import { CartAnalyticsMixin } from '../../../shared/mixins/cart-analytics.mixin'
import { IProfileService, ProfileServiceType } from '../../../profile/contracts'
import { ShipmentRegion } from '../../../shared/services/dictionary'
import { UserModel } from '../../../auth/shared'
import { validatePayload } from '../../../shared/support/validate-payload'

import { CartMutationTypes, CheckoutStepCallback } from '../../contracts'
import {
  FormContextSwitchContracts
} from '../../molecules/FormContextSwitch/FormContextSwitch.contracts'
import { GuestDetailsFormFieldsetTwo } from '../../molecules/GuestDetailsFormFieldset'
import { NoteForm } from '../../molecules/NoteForm'
import { translateCheckoutPayloadToValidationObject } from '../../helpers/checkout.helpers'

import { AbstractStep } from '../AbstractStep'
import { AddressSelector } from '../AddressSelector'
import {
  customerDetailsValidatorsMap
} from '../CustomerDetailsStep/CustomerDetailsStep.helpers'
import {
  DELIVERY_DETAILS_STEP_COMPONENT_CONFIG_MAP,
  DELIVERY_DETAILS_STEP_COMPONENT_KEY, deliveryDetailsContextSwitchComponentsRegistry
} from './DeliveryDetailsStep.config'
import { Shippings } from '../Shippings'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component<DeliveryDetailsStep>({
  name: 'DeliveryDetailsStep',
  components: { AddressSelector, NoteForm, Shippings, GuestDetailsFormFieldsetTwo },
  created (): void {
    this.config = this.getComponentConfig(
      DELIVERY_DETAILS_STEP_COMPONENT_KEY,
      { ...DELIVERY_DETAILS_STEP_COMPONENT_CONFIG_MAP }
    )
  }
})
export class DeliveryDetailsStep extends Mixins<AbstractStep, CartAnalyticsMixin,
  StructureConfigurable>(AbstractStep, CartAnalyticsMixin, StructureConfigurable) {
  @Prop({ type: Number, required: true })
  public currentStep!: number

  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @Inject(AuthServiceType)
  protected readonly authService!: Authentication<UserModel>

  @Inject(ProfileServiceType)
  protected readonly profileService!: IProfileService

  public errors: Record<string, string[]> | null = null
  public shippingErrors: string[] = []

  public isForBilling: boolean = true
  public isFormVisible: boolean = false
  public callbacks: CheckoutStepCallback[] = []

  public get selectedShipping (): ShippingMethod | null {
    return this.payload.shipping
  }

  public set selectedShipping (value: ShippingMethod | null) {
    this.onChange('shipping', value)
  }

  public get note (): string {
    return this.payload.note
  }

  public set note (note: string) {
    this.onChange('note', note)
  }

  public get billingAddress (): CartBillingAddress | null {
    return this.cart.billingAddress
  }

  public get inputConfig (): boolean {
    return this.getConfigProperty('input')
  }

  public get contextSwitchComponent (): string {
    return this.getConfigProperty('contextSwitchComponent')
  }

  public get contextSwitchComponentProps (): FormContextSwitchContracts {
    return this.getConfigProperty('contextSwitchComponentProps')
  }

  public get withCompanyCheckboxFields (): boolean {
    return this.getConfigProperty('withCompanyCheckboxFields')
  }

  public get hasRegionField (): boolean {
    return this.getConfigProperty('hasRegionField')
  }

  public get hasTextareaHeading (): boolean {
    return this.getConfigProperty('hasTextareaHeading')
  }

  public get addressFormData () {
    return {
      address: this.payload.address,
      user: this.payload.user
    }
  }

  /**
   * FIXME: Change AnyObject to a type.
   */
  public set addressFormData (values: AnyObject) {
    for (const [key, value] of Object.entries(values)) {
      this.onChange(key, value)
    }
  }

  public get switchComponent (): VueConstructor | undefined {
    if (!(this.contextSwitchComponent in deliveryDetailsContextSwitchComponentsRegistry)) {
      return
    }
    return deliveryDetailsContextSwitchComponentsRegistry[this.contextSwitchComponent]
  }

  public clearErrors (): void {
    this.errors = null
  }

  public static isValidStep (
    payload: AnyObject,
    translate: (key: string) => TranslateResult,
    locale: string,
    setErrors?: (errors: Record<string, string[]>) => void
  ): boolean {
    const result = validatePayload(
      payload,
      customerDetailsValidatorsMap,
      translate,
      locale
    )

    if (result.errors && setErrors) {
      setErrors(result.errors)
    }

    return !result.errors || Object.keys(result.errors).length === 0
  }

  public onAddressEdit (): void {
    this.isFormVisible = !this.isFormVisible
  }

  public onAnotherAddress (): void {
    this.isFormVisible = !this.isFormVisible
  }

  /**
   * Handles @onAddress event of `AddressSelector` component.
   */
  public async onAddressSelection (payload: { id: number; address: AddressData } | undefined): Promise<void> {
    if (!payload) {
      return
    }

    this.$emit('saving', true)

    this.mapUserAddressToCheckoutPayload(payload.address, this.hasRegionField)

    try {
      await this.profileService.updateDeliveryAddress(payload.id, {
        ...payload.address,
        defaultBilling: true
      })

      if (!this.authService.token) {
        return
      }

      const user = await this.profileService.getUserDetails(this.authService.token.accessToken)
      this.authService.setUser(user)
      this.$store.commit('profile/SET_USER', user)
    } catch (e) {
      logger(e, 'error')
    } finally {
      this.$emit('saving', false)
    }
  }

  /**
   * Translates & updates user address for checkout payload
   */
  protected mapUserAddressToCheckoutPayload (payload: AddressData, hasRegion: boolean = false): void {
    const { street, company, city, postalCode, vatId, phoneNumber, countryCode } = payload
    const { lastName, firstName } = payload

    this.updateValues({
      address: {
        address: street,
        billingAddress: {
          address: street,
          postalCode,
          city
        },
        country: countryCode,
        company: !company ? '' : company,
        city,
        invoice: {
          company: !company ? '' : company,
          nip: vatId
        },
        postalCode,
        region: hasRegion ? payload.region : null,
        phone: phoneNumber
      },
      user: {
        email: this.payload.user.email,
        firstName,
        lastName
      }
    })
  }

  public async submit (): Promise<void> {
    if (!this.selectedShipping) {
      this.shippingErrors = [this.$t('front.checkout.organisms.ShippingStep.error').toString()]

      return
    }

    this.shippingErrors = []

    this.$emit('saving', true)

    /**
     * FIXME
     *
     * Temporarily fixing bug #39196
     */
    let payload = this.payload
    if (this.currentStep === 2 || this.currentStep === 3 || this.currentStep === 4) {
      payload = {
        ...this.payload,
        acceptContent: true
      }
    }

    if (!this.isForBilling) {
      if (
        !DeliveryDetailsStep.isValidStep(
          translateCheckoutPayloadToValidationObject(payload),
          this.$t.bind(this),
          this.$i18n.locale,
          this.setErrors
        )
      ) {
        this.$emit('saving', false)
        return
      }

      try {
        let payload: CartShippingAddress = {
          company: this.addressFormData.address.company,
          city: this.addressFormData.address.city,
          countryCode: this.addressFormData.address.country,
          lastname: this.addressFormData.user.firstName,
          firstname: this.addressFormData.user.lastName,
          postcode: this.addressFormData.address.postalCode,
          customerNotes: this.note,
          telephone: this.addressFormData.address.phone,
          street: [this.addressFormData.address.address],
          vatId: this.addressFormData.address.invoice?.nip
        }

        const region = this.addressFormData.address.region
        if (this.hasRegionField && region) {
          payload = {
            ...payload,
            region: (region as ShipmentRegion).id.toString()
          }
        }

        await this.checkoutService.setShippingAddress(this.cart.id, payload, false, this.hasRegionField)
      } catch (e) {
        logger(e, 'error')
      }
    }

    try {
      let cart = await this.checkoutService.setShippingMethod(this.cart.id, this.selectedShipping)
      for (const c of this.callbacks) {
        const returned = await c.callable.apply(c.onThis, c.args)
        if (typeof returned === 'object' && returned !== null) {
          cart = returned
        }
      }

      this.$store.commit(CartMutationTypes.SetCart, cart)

      this.eventBus.emit('app:checkout.addShippingInfo', {
        ...this.getBaseCheckoutPayload(this.cart),
        shippingTier: this.cart.selectedShippingMethod?.methodCode
      })
      this.nextStep()
    } catch (e) {
      logger(e, 'error')
      this.showToast((e as Error).message, 'warning')
    } finally {
      this.$emit('saving', false)
    }
  }

  public setErrors (errors: Record<string, string[]>): void {
    this.errors = errors
  }
}

export default DeliveryDetailsStep
